<template
  :routePreventDialog="routePreventDialog"
  :customClass="'leave-update'"
  v-if="getPermission('leave:update') || getPermission('leave:create')"
>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      width="600"
      right
      temporary
      stateless
      style="z-index: 99"
    >
      <div class="drawer-wrapper">
        <v-form
          ref="leaveForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="CreateOrupdateLeave"
        >
          <v-flex
            class="py-1 px-5 d-flex align-items-center white--text orange darken-2"
          >
            <div class="form-title my-auto" v-if="leaveCreate.id > 0">
              Update Leave
            </div>
            <div class="form-title my-auto" v-else>Create Leave</div>
            <v-spacer></v-spacer>
            <v-btn
              tile
              :loading="formLoading"
              class="custom-bold-button"
              color="cyan white--text"
              v-on:click="CreateOrupdateLeave"
              >Save</v-btn
            >
            <!-- <v-btn tile color="" class="ml-2" v-on:click="$emit('close')" -->
            <v-btn tile color="" class="ml-2" v-on:click="$emit('close')"
              >Cancel</v-btn
            >
          </v-flex>
          <div class="drawer-content pt-5 px-5">
            <div class="d-form-grp" v-if="!is_staff">
              <label class="my-auto fw-500 required" required
                >Select Technicians
              </label>
              <v-autocomplete
                hide-details
                dense
                solo
                flat
                placeholder="Select Technicians"
                :items="engineerList"
                :disabled="pageLoading"
                :loading="pageLoading"
                return-object
                item-text="full_name"
                v-on:change="getCategoryOptions"
                v-model.trim="leaveCreate.staff"
                :rules="[
                  validateRules.required(
                    leaveCreate.staff,
                    'Select Technicians'
                  ),
                ]"
              ></v-autocomplete>
            </div>
            <div class="d-form-grp mt-4">
              <label class="my-auto fw-500 required" required>
                Leave Type</label
              >
              <v-autocomplete
                hide-details
                dense
                solo
                flat
                placeholder="Leave Type"
                item-value="category"
                v-model="leaveCreate.category"
                :items="categoryList"
                item-text="category"
                v-on:change="categoryDays"
                :rules="[
                  validateRules.required(leaveCreate.category, 'Leave Type'),
                ]"
              >
                <template slot="selection" slot-scope="{ item }">
                  {{ item.category }}
                </template>
                <template slot="item" slot-scope="{ item }">
                  <!-- <v-list-tile-content> -->
                  <p class="fullName">
                    {{ item.category }}
                    <span v-if="item.category != 'Un Paid'">
                      ( {{ item.allocated_days }} )
                    </span>
                  </p>
                  <!--  </v-list-tile-content> -->
                </template>
              </v-autocomplete>
            </div>
            <div class="d-form-grp mt-4" v-if="remainingLeaves">
              <label class="my-auto fw-500">Leave Balance</label>
              <b>{{ remainingLeaves }} Days</b>
            </div>
            <div class="d-form-grp mt-4" v-if="remainingLeaves == 0">
              <label class="my-auto fw-500">Leave Balance</label>
              <b>0 Days</b>
            </div>
            <div class="d-form-grp mt-4">
              <label class="my-auto fw-500">Reason</label>
              <v-text-field
                hide-details
                dense
                solo
                flat
                placeholder="Reason"
                v-model.trim="leaveCreate.reason"
              ></v-text-field>
            </div>
            <div class="d-form-grp mt-4">
              <label class="my-auto fw-500 required">Start Date</label>
              <Datepicker
                solo
                flat
                :mandatory="true"
                :nullable="leaveCreate && leaveCreate.id > 0 ? false : true"
                placeholder="Start Date"
                v-model="start_date"
                :min-date="minDate"
                :max-date="getMaxDate(minDate, remainingLeaves)"
                :default-date="start_date"
              ></Datepicker>
            </div>
            <div class="d-form-grp mt-4">
              <label class="my-auto fw-500">End Date </label>

              <template
                v-if="leaveCreate && leaveCreate.id > 0 && end_date == null"
              >
                <Datepicker
                  solo
                  flat
                  :nullable="leaveCreate"
                  placeholder="End Date"
                  v-model.trim="end_date"
                  :min-date="start_date"
                  :max-date="maxDate"
                  :default-date="end_date"
                ></Datepicker>
              </template>
              <template v-else>
                <Datepicker
                  solo
                  flat
                  :nullable="leaveCreate && leaveCreate.id > 0 ? false : true"
                  placeholder="End Date"
                  v-model.trim="end_date"
                  :min-date="start_date"
                  :max-date="leaveCreate.category == 'Un Paid' ? null : maxDate"
                  :default-date="end_date"
                ></Datepicker>
              </template>

              <!--<Datepicker
                solo
                flat
                v-model.trim="leaveCreate.end_date"
                :min-date="leaveCreate.start_date"
              ></Datepicker>-->
            </div>
            <template v-if="start_date == end_date || start_date > end_date">
              <div class="d-form-grp mt-4">
                <label class="my-auto fw-500">Half Day</label>
                <v-switch
                  v-model="leaveCreate.duration"
                  dense
                  hide-details
                  class="ma-0"
                ></v-switch>
              </div>

              <div class="d-form-grp mt-4" v-if="leaveCreate.duration == 1">
                <label class="my-auto fw-500"></label>

                <v-radio-group
                  v-model="leaveCreate.duration_type"
                  row
                  dense
                  hide-details
                  class="mt-0"
                >
                  <v-radio label="First half" value="1" class="ma-0"></v-radio>
                  <v-radio label="Second half" value="2" class="ma-0"></v-radio>
                </v-radio-group>
              </div>
            </template>
          </div>
        </v-form>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
import { LeaveApplyEventBus } from "@/core/lib/leave.from.lib.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  CLEAR_ERROR,
  POST,
  QUERY,
  GET,
  PUT,
} from "@/core/services/store/request.module";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import moment from "moment-timezone";
import { currentUser } from "@/core/services/jwt.service";
import { toSafeInteger } from "lodash";

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "CreateLeave",
  components: {
    Datepicker,
  },
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
    leaveUpdateData: {
      type: Object,
      default: new Object({}),
    },
  },
  data() {
    return {
      minDate: new Date().toISOString().substr(0, 10),
      leave: null,
      engineerId: null,
      userId: null,
      updateDialog: false,
      pageLoading: false,
      categoryList: new Array(),
      start_date: null,
      end_date: null,
      maxDate: null,
      engineerList: new Array(),
      remainingLeaves: null,
      leaveList: [
        {
          category: null,
          maxdays: null,
          remainig_days: null,
        },
      ],
      leaveCreate: new Object({
        id: null,
        staff: null,
        role: null,
        leave: null,
        category: null,
        remainig_days: null,
        duration: 0,
        duration_type: 1,
        reason: null,
      }),
    };
  },
  watch: {
    drawer(param) {
      if (param) {
        this.init();
      }
    },
    start_date(param) {
      //console.log(param,"param1")
      if (param) {
        // console.log(param,"param")
        this.categoryDays();
      }
    },
  },
  methods: {
    init() {
      const param = this.leaveUpdateData;
      //console.log(param, "param");
      if (param && param.id) {
        this.leaveCreate = {
          id: param.id,
          staff: param.role == 2 ? param.staff : param.user_data,
          category: (param.category && param.category) || null,
          duration: param.duration || 0,
          duration_type: param.duration_type || 1,
          reason: param.reason || null,
          role: param.role || null,
        };
        this.start_date = param.start_date || null;
        this.end_date = param.end_date || null;
        // console.log(this.leaveCreate,"this.leaveCreate");
        if (this.leaveCreate) {
          //console.log(this.leaveCreate.staff,"this.leaveCreate.staff");
          this.getCategoryOptions(this.leaveCreate);
        }
      }
    },
    getMaxDate(mindate, remainig_days) {
      //console.log(remainig_days, "mindate");

      if (remainig_days) {
        if (remainig_days <= 0) {
          return moment(mindate, "YYYY-MM-DD")
            .add(Math.floor(remainig_days) - 1, "days")
            .format("YYYY-MM-DD");
        }
        // return null;
      }
    },
    getCurrentUser() {
      const user = currentUser();
      if (user && user.role_name == "Staff") {
        this.leaveCreate.staff = user.id;
      }
      //console.log(user);
      return user;
    },
    getOptions() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "leave/options",
        })
        .then(({ data }) => {
          _this.engineerList = data.engineer;
          //_this.engineerList = data.user;
          // console.log(_this.engineerList,"_this.engineerList");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    categoryDays() {
      const _this = this;
      let categoryData = _this.lodash.filter(
        _this.categoryList,
        function (row) {
          // console.log(_this.leaveCreate.category,"_this.leaveCreate.category");
          return row.category === _this.leaveCreate.category;
        }
      );
      //  console.log(categoryData, "categoryData");
      /* let todayDate = moment().format('YYYY-MM-DD');
      _this.minDate = moment(todayDate, "YYYY-MM-DD")
        .add(Math.floor(categoryData[0].remainig_days) - 1, "days")
        .format("YYYY-MM-DD"); */

      _this.maxDate = moment(this.start_date, "YYYY-MM-DD")
        .add(Math.floor(categoryData[0].remainig_days) - 1, "days")
        .format("YYYY-MM-DD");

      //console.log(_this.maxDate,"ffff");

      this.remainingLeaves = categoryData[0].remainig_days;

      // console.log(this.remainingLeaves,"ffff");
    },

    getCategoryOptions(engineerList) {
      // alert(43)
      console.log(engineerList, "engineerList");
      /* if (userList == 0) {
        const user = currentUser();
        if (user && user.role_name == "staff") {
          userList = user.id;
        } else {
          return false;
        }
      } */
      if (engineerList.role == 2) {
        //staff: param.role == 2 ? (param.staff) : param.user_data,
        if (engineerList.staff) {
          this.engineerId = engineerList.staff.id;
        } else {
          this.engineerId = engineerList.id;
        }

        const _this = this;
        _this.pageLoading = true;
        _this.$store
          .dispatch(GET, {
            url: "engineer/leave/option/" + this.engineerId,
          })
          .then(({ data }) => {
            if (data.length == 0) {
              _this.categoryList = [{ category: "Un Paid" }];
            } else {
              _this.categoryList = data;
            }

            //  _this.categoryList = data;
            _this.categoryDays();
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.pageLoading = false;
          });
      }
      if (engineerList.role == 1) {
        if (engineerList.staff) {
          this.userId = engineerList.staff.id;
        } else {
          this.userId = engineerList.id;
        }

        const _this = this;
        _this.pageLoading = true;
        _this.$store
          .dispatch(GET, {
            url: "user/leave/option/" + this.userId,
          })
          .then(({ data }) => {
            if (data.length == 0) {
              _this.categoryList = [{ category: "Un Paid" }];
            } else {
              _this.categoryList = data;
            }

            _this.categoryDays();
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.pageLoading = false;
          });
      }
    },
    getLeave1(status, curentPage) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "leave",
          data: { status: status, current_page: curentPage },
        })
        .then(({ data }) => {
          if (status == "pending") {
            this.pending = this.lodash.concat(_this.pending, data.rows);
            _this.current_page = data.current_page + 1;
          } else if (status == "approved") {
            this.approved = this.lodash.concat(_this.approved, data.rows);
            _this.current_pageone = data.current_page + 1;
          } else if (status == "rejected") {
            this.rejected = this.lodash.concat(_this.rejected, data.rows);
            _this.current_pagetwo = data.current_page + 1;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    /* CreateLeave() {
      const _this = this;
      if (!_this.$refs.leaveForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let formData = new Object({
        staff: _this.leaveCreate.staff || null,
        duration: _this.leaveCreate.duration || 0,
        duration_type: _this.leaveCreate.duration_type || 1,
        start_date: _this.leaveCreate.start_date || null,
        end_date: _this.leaveCreate.end_date || null,
        reason: _this.leaveCreate.reason || null,
      });

      _this.$store
        .dispatch(POST, {
          url: "leave" ,
          data: formData,
        })
        .then(({ data }) => {
          _this.$router.push(
            _this.getDefaultRoute("leave", {
              params: {
                id: data.id,
              },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },*/

    CreateOrupdateLeave() {
      const _this = this;
      if (!_this.$refs.leaveForm.validate()) {
        return false;
      }
      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});
      let leaveId = _this.leaveCreate.id || 0;
      let staff = _this.leaveCreate.staff.id || null;
      let role = toSafeInteger(_this.leaveCreate.staff.role) || null;
      if (!staff) {
        const user = currentUser();
        staff = user.id;
      }
      if (role == 1) {
        let formData = new Object({
          id: leaveId,
          user: _this.leaveCreate.staff.id || null,
          role,
          category: _this.leaveCreate.category || null,
          duration: _this.leaveCreate.duration || 0,
          duration_type: _this.leaveCreate.duration_type || 1,
          start_date: _this.start_date || null,
          end_date: _this.end_date || null,
          reason: _this.leaveCreate.reason || null,
        });
        if (leaveId > 0) {
          _this.updateLeavecase(formData, leaveId);
        } else {
          _this.createLeavecase(formData);
        }
      }
      if (role == 2) {
        let formData = new Object({
          id: leaveId,
          staff: _this.leaveCreate.staff.id || null,
          role,
          category: _this.leaveCreate.category || null,
          duration: _this.leaveCreate.duration || 0,
          duration_type: _this.leaveCreate.duration_type || 1,
          start_date: _this.start_date || null,
          end_date: _this.end_date || null,
          reason: _this.leaveCreate.reason || null,
        });
        if (leaveId > 0) {
          _this.updateLeavecase(formData, leaveId);
        } else {
          _this.createLeavecase(formData);
        }
      }
    },

    /*  CloseLeave() {
      location.reload();
    }, */

    updateLeavecase(formData, leaveId) {
      const _this = this;
      _this.$store
        .dispatch(PUT, {
          url: "leave/" + leaveId,
          data: formData,
        })
        .then((data) => {
          // console.log(data);
          LeaveApplyEventBus.$emit("reload:leave", data);
          // LeaveFormEventBus.$emit("refresh", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally((data) => {
          _this.formLoading = false;
          LeaveApplyEventBus.$emit("reload:leave", data);
          _this.$emit("close");
          //  location.reload();
        });
    },
    createLeavecase(formData) {
      //console.log(formData, "formData");
      const _this = this;
      _this.$store
        .dispatch(POST, {
          url: "leave",
          data: formData,
        })
        .then(({ data }) => {
          _this.data = data;
          LeaveApplyEventBus.$emit("reload:leave");
          _this.$emit("close");
          // location.reload();
        })
        .catch((error) => {
          _this.logError(error);
          _this.dialog = false;
        })
        .finally(() => {
          _this.formLoading = false;
          // LeaveApplyEventBus.$emit("reload:leave");
          _this.$emit("close");
        });
    },
    getLeave() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "leave/" + _this.leave,
        })
        .then(({ data }) => {
          _this.leaveCreate = new Object({
            staff: data.staff ? data.staff.staff : null,

            duration: data.duration,
            duration_type: data.duration_type,
            reason: data.reason,
            status: data.status,
          });
          _this.start_date = data.start_date;
          _this.end_date = data.end_date;
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  /*  created() {
    this.getCategoryOptions();
  }, */
  mounted() {
    const _this = this;
    _this.getOptions();
    _this.init();

    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Leave", route: "leave" },
      { title: "create" },
    ]);
    this.leave = this.lodash.toSafeInteger(_this.$route.params.id);

    /* if (_this.leave <= 0) {
      _this.goBack();
    } */
    //_this.getLeave();
  },
  computed: {
    is_staff() {
      const user = this.getCurrentUser();
      const role_name = this.lodash.toLower(user.role_name);
      return role_name == "staff";
    },
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    _this.routePreventDialog = true;
    PreventDialogEventBus.$on("stay-on-page", function () {
      _this.routePreventDialog = false;
      next(false);
    });
    PreventDialogEventBus.$on("leave-page", function () {
      _this.routePreventDialog = false;
      next();
    });
  },
};
</script>
