import { render, staticRenderFns } from "./CreateLeave.vue?vue&type=template&id=bad3112c&%3AroutePreventDialog=routePreventDialog&%3AcustomClass='leave-update'&v-if=getPermission('leave%3Aupdate')%20%7C%7C%20getPermission('leave%3Acreate')"
import script from "./CreateLeave.vue?vue&type=script&lang=js"
export * from "./CreateLeave.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports