<template>
  <div class="leave-kanban">
    <div class="kanban-view-list">
      <v-flex class="kanban-innerlist">
        <div class="list-title">
          <div class="d-flex">
            <div class="fw-600 title-text">Pending</div>
            <v-spacer></v-spacer>
            <!-- <v-checkbox
              v-on:change="manageAllCheckboxPending"
              v-model="allPendingCheckbox"
              hide-details
              class="ma-0 pa-0 size-16"
            >
            </v-checkbox> -->
          </div>
          <div class="mt-1">
            <v-chip
              class="mb-1 mr-1"
              label
              color="orange darken-4 white--text"
              x-small
            >
              {{ pending.length }} leave
            </v-chip>
          </div>
        </div>
        <Container
          group-name="leadkanbanlist"
          @drop="onPendingDrop('pending', $event)"
          :get-child-payload="getChildPayload1"
          :drop-placeholder="dropPlaceholderOptions"
          class="overflow-list-item"
          :id="!pending.length ? 'blank-list' : ''"
        >
          <Draggable v-for="(data, index) in pending" :key="index" class="">
            <div class="list-items" v-if="data.role == 1">
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Technicians :</span>
                <span
                  class="fw-500"
                  v-if="data && data.user_data && data.user_data.full_name"
                >
                  {{ data.user_data.full_name }}
                </span>
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Category :</span>
                <span class="fw-500">{{ data.category }}</span>
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Start Date : </span>
                <span class="fw-500">{{ formatDate(data.start_date) }}</span>
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">End Date : </span>
                <span class="fw-500">{{ formatDate(data.end_date) }}</span>
              </div>
              <div class="pr-6">
                <span class="fw-700 pr-2">Reason : </span>
                <span class="fw-500">{{ data.reason }}</span>
              </div>
              <template v-if="data.duration">
                <span class="fw-700 pr-2">Half Day : </span>
                <v-chip
                  class="mb-1 mr-1"
                  label
                  color="orange white--text"
                  x-small
                >
                  {{ data.duration_type_text }}
                </v-chip>
              </template>
              <!-- <v-checkbox
                hide-details
                v-bind:value="data.id"
                v-on:change="manageSelectBox"
                v-model="pendingCheckbox"
                class="ma-0 pa-0 size-16"
              >
              </v-checkbox> -->
              <v-icon
                class="ma-0 pa-0 size-16 float-right"
                v-on:click="updateLeave(data.id)"
                color="cyan"
                small
                >mdi-pencil</v-icon
              >
            </div>

            <div class="list-items" v-if="data.role == 2">
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Technicians :</span>
                <span
                  class="fw-500"
                  v-if="data && data.staff && data.staff.full_name"
                >
                  {{ data.staff.full_name }}
                </span>
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Category :</span>
                <span class="fw-500">{{ data.category }}</span>
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Start Date : </span>
                <span class="fw-500">{{ formatDate(data.start_date) }}</span>
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">End Date : </span>
                <span class="fw-500">{{ formatDate(data.end_date) }}</span>
              </div>
              <div class="pr-6">
                <span class="fw-700 pr-2">Reason : </span>
                <span class="fw-500">{{ data.reason }}</span>
              </div>
              <template v-if="data.duration">
                <span class="fw-700 pr-2">Half Day : </span>
                <v-chip
                  class="mb-1 mr-1"
                  label
                  color="orange white--text"
                  x-small
                >
                  {{ data.duration_type_text }}
                </v-chip>
              </template>
              <!-- <v-checkbox
                hide-details
                v-bind:value="data.id"
                v-on:change="manageSelectBox"
                v-model="pendingCheckbox"
                class="ma-0 pa-0 size-16"
              >
              </v-checkbox> -->
              <v-icon
                class="ma-0 pa-0 size-16 float-right"
                v-on:click="updateLeave(data.id)"
                color="cyan"
                small
                >mdi-pencil</v-icon
              >
            </div>
          </Draggable>
          <v-btn
            v-if="pending.length < pendingTotalRows"
            class="mx-2 custom-bold-button"
            color="cyan white--text"
            v-on:click="getLeave('pending', current_page)"
          >
            Load More...
          </v-btn>
        </Container>

        <Dialog :common-dialog="confirm_dialog">
          <template v-slot:title> Confirmation </template>
          <template v-slot:body>
            <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
              <v-container class="px-10" fluid>
                <!-- <p class="font-weight-600 font-size-19">
                Please confirm if contract already
                <span class="font-weight-700"></span
                >?
              </p> -->

                <v-row class="delete-dialog">
                  <!-- <v-col md="12" class="py-0 my-auto">
                    <p
                      class="btx-p m-0 text-align-center"
                      style="text-align: center"
                    >
                      <span
                        class="red--text lighten-1 font-level-1-bold"
                        style="
                          font-size: 19px !important;
                          color: #24326d !important;
                        "
                        >Are you sure, You want to
                        <template> Approve and </template
                        ><template> Reject this Leave </template>?</span
                      >
                    </p>
                  </v-col> -->

                  <v-flex>
                    <label class="font-weight-500 font-size-16">Remark</label>
                    <v-textarea
                      v-model.trim="leave_remark"
                      auto-grow
                      dense
                      filled
                      color="cyan"
                      label="Remark"
                      solo
                      flat
                      row-height="25"
                    ></v-textarea>
                  </v-flex>
                </v-row>
              </v-container>
            </v-form>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="confirm_loading"
              v-on:click="
                confirm_dialog = false;
                leave_remark = null;

                getLeave('pending');
                getLeave('rejected');
                getLeave('approved');
              "
              class="mx-2 custom-grey-border custom-bold-button"
            >
              Close
            </v-btn>
            <v-btn
              v-on:click="approve_submit('approved')"
              :disabled="!formValid || confirm_loading"
              :loading="confirm_loading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
            >
              Approve
            </v-btn>
            <v-btn
              v-on:click="reject_submit('rejected')"
              :disabled="!formValid || confirm_loading"
              :loading="confirm_loading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
            >
              Reject
            </v-btn>
          </template>
        </Dialog>
      </v-flex>
      <v-flex class="kanban-innerlist">
        <div class="list-title">
          <div class="d-flex">
            <div class="mr-2 fw-700 title-text">Approved</div>
            <v-spacer></v-spacer>
            <!-- <v-checkbox
              v-on:change="manageAllCheckboxApproved"
              v-model="allApproveCheckbox"
              hide-details
              class="ma-0 pa-0 size-16"
            >
            </v-checkbox> -->
          </div>
          <div class="mt-1">
            <v-chip class="mb-1 mr-1" label color="green white--text" x-small>
              {{ approved.length }} leave
            </v-chip>
          </div>
        </div>
        <Container
          group-name="leadkanbanlist"
          @drop="onApprovedDrop('approved', $event)"
          :get-child-payload="getChildPayload2"
          :drop-placeholder="dropPlaceholderOptions"
          class="overflow-list-item"
          :id="!approved.length ? 'blank-list' : ''"
        >
          <div v-for="(data, index) in approved" :key="index" class="">
            <div class="list-items" v-if="data.role == 1">
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Technicians : </span>
                <span
                  class="fw-500"
                  v-if="data && data.user_data && data.user_data.full_name"
                  >{{ data.user_data.full_name }}</span
                >
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Category :</span>
                <span class="fw-500">{{ data.category }}</span>
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Start Date : </span>
                <span class="fw-500">{{ formatDate(data.start_date) }}</span>
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">End Date : </span>
                <span class="fw-500">{{ formatDate(data.end_date) }}</span>
              </div>
              <div class="pr-6">
                <span class="fw-700 pr-2">Reason : </span>
                <span class="fw-500">{{ data.reason }}</span>
              </div>
              <div class="pr-6 d-flex" v-if="data && data.leave_remark">
                <span class="fw-700 text-nowrap">Remark : </span>
                <span class="fw-500" style="white-space: break-spaces">
                  <read-more
                    class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                    more-str="read more"
                    :text="data.leave_remark"
                    link="#"
                    less-str="read less"
                    :max-chars="120"
                  >
                  </read-more>
                </span>
              </div>
              <template v-if="data.duration">
                <span class="fw-700 pr-2">Half Day : </span>
                <v-chip
                  class="mb-1 mr-1"
                  label
                  color="orange white--text"
                  x-small
                >
                  {{ data.duration_type_text }}
                </v-chip>
              </template>
            </div>
            <div class="list-items" v-if="data.role == 2">
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Technicians : </span>
                <span
                  class="fw-500"
                  v-if="data && data.staff && data.staff.full_name"
                  >{{ data.staff.full_name }}</span
                >
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Category :</span>
                <span class="fw-500">{{ data.category }}</span>
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Start Date : </span>
                <span class="fw-500">{{ formatDate(data.start_date) }}</span>
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">End Date : </span>
                <span class="fw-500">{{ formatDate(data.end_date) }}</span>
              </div>
              <div class="pr-6">
                <span class="fw-700 pr-2">Reason : </span>
                <span class="fw-500">{{ data.reason }}</span>
              </div>
              <div class="pr-6 d-flex" v-if="data && data.leave_remark">
                <span class="fw-700 text-nowrap">Remark : </span>
                <span class="fw-500" style="white-space: break-spaces">
                  <read-more
                    class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                    more-str="read more"
                    :text="data.leave_remark"
                    link="#"
                    less-str="read less"
                    :max-chars="120"
                  >
                  </read-more>
                </span>
              </div>
              <template v-if="data.duration">
                <span class="fw-700 pr-2">Half Day : </span>
                <v-chip
                  class="mb-1 mr-1"
                  label
                  color="orange white--text"
                  x-small
                >
                  {{ data.duration_type_text }}
                </v-chip>
              </template>
            </div>
          </div>
          <v-btn
            v-if="approved.length < approvedTotalRows"
            class="mx-2 custom-bold-button"
            color="cyan white--text"
            v-on:click="getLeave('approved', current_pageone)"
          >
            Load More...
          </v-btn>
        </Container>
      </v-flex>
      <v-flex class="kanban-innerlist">
        <div class="list-title">
          <div class="d-flex">
            <div class="mr-2 fw-700 title-text">Rejected</div>
            <v-spacer></v-spacer>
            <!--  <v-checkbox
              v-on:change="manageAllCheckboxRejected"
              v-model="allRejectCheckbox"
              hide-details
              class="ma-0 pa-0 size-16"
            >
            </v-checkbox> -->
          </div>
          <div class="mt-1">
            <v-chip
              class="mb-1 mr-1"
              label
              color="red white--text darken-4"
              x-small
            >
              {{ rejected.length }} leave
            </v-chip>
          </div>
        </div>
        <Container
          group-name="leadkanbanlist"
          :get-child-payload="getChildPayload3"
          :drop-placeholder="dropPlaceholderOptions"
          class="overflow-list-item"
          :id="!rejected.length ? 'blank-list' : ''"
        >
          <div v-for="(data, index) in rejected" :key="index" class="">
            <div class="list-items" v-if="data.role == 1">
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Technicians :</span>
                <span
                  class="fw-500"
                  v-if="data && data.user_data && data.user_data.full_name"
                  >{{ data.user_data.full_name }}</span
                >
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Category :</span>
                <span class="fw-500">{{ data.category }}</span>
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Start Date :</span>
                <span class="fw-500">{{ formatDate(data.start_date) }}</span>
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">End Date : </span>
                <span class="fw-500">{{ formatDate(data.end_date) }}</span>
              </div>

              <div class="pr-6">
                <span class="fw-700 pr-2">Reason : </span>
                <span class="fw-500">{{ data.reason }}</span>
              </div>

              <div class="pr-6 d-flex" v-if="data && data.leave_remark">
                <span class="fw-700 text-nowrap">Remark : </span>
                <span class="fw-500" style="white-space: break-spaces">
                  <read-more
                    class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                    more-str="read more"
                    :text="data.leave_remark"
                    link="#"
                    less-str="read less"
                    :max-chars="120"
                  >
                  </read-more>
                </span>
              </div>

              <template v-if="data.duration">
                <span class="fw-700 pr-2">Half Day : </span>
                <v-chip
                  class="mb-1 mr-1"
                  label
                  color="orange white--text"
                  x-small
                >
                  {{ data.duration_type_text }}
                </v-chip>
              </template>
            </div>
            <div class="list-items" v-if="data.role == 2">
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Technicians :</span>
                <span
                  class="fw-500"
                  v-if="data && data.staff && data.staff.full_name"
                  >{{ data.staff.full_name }}</span
                >
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Category :</span>
                <span class="fw-500">{{ data.category }}</span>
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">Start Date :</span>
                <span class="fw-500">{{ formatDate(data.start_date) }}</span>
              </div>
              <div class="ellipsis pr-6">
                <span class="fw-700 pr-2">End Date : </span>
                <span class="fw-500">{{ formatDate(data.end_date) }}</span>
              </div>

              <div class="pr-6">
                <span class="fw-700 pr-2">Reason : </span>
                <span class="fw-500">{{ data.reason }}</span>
              </div>
              <div class="pr-6 d-flex" v-if="data && data.leave_remark">
                <span class="fw-700 text-nowrap">Remark : </span>
                <span class="fw-500" style="white-space: break-spaces">
                  <read-more
                    class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                    more-str="read more"
                    :text="data.leave_remark"
                    link="#"
                    less-str="read less"
                    :max-chars="120"
                  >
                  </read-more>
                </span>
              </div>
              <template v-if="data.duration">
                <span class="fw-700 pr-2">Half Day : </span>
                <v-chip
                  class="mb-1 mr-1"
                  label
                  color="orange white--text"
                  x-small
                >
                  {{ data.duration_type_text }}
                </v-chip>
              </template>
            </div>
          </div>
          <v-btn
            v-if="rejected.length < rejectTotalRows"
            class="mx-2 custom-bold-button"
            color="cyan white--text"
            v-on:click="getLeave('rejected', current_pagetwo)"
          >
            Load More...
          </v-btn>
        </Container>
      </v-flex>
    </div>
  </div>
</template>
<script>
import { Container, Draggable } from "vue-smooth-dnd";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { QUERY, PATCH } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog";
import { LeaveApplyEventBus } from "@/core/lib/leave.from.lib.js";
export default {
  mixins: [CommonMixin, ListingMixin],
  name: "LeaveKanban",
  data() {
    return {
      dropPlaceholderOptions: {
        className: "lead-drop-preview",
        animationDuration: "50",
        showOnTop: true,
      },
      confirmationData: {},
      statusapprove: null,
      categoryList: new Array(),
      confirm_loading: false,
      category: null,
      allselect: false,
      confirm_dialog: false,
      pendingTotalRows: 0,
      approvedTotalRows: 0,
      rejectTotalRows: 0,
      leave: null,
      staff: null,
      leave_remark: null,
      start_date: null,
      userList: new Array(),
      end_date: null,
      allPendingCheckbox: false,
      allApproveCheckbox: false,
      allRejectCheckbox: false,
      reason: null,
      pageLoading: false,
      CreateLeave: false,
      update_loading: false,
      current_page: 1,
      current_pageone: 1,
      current_pagetwo: 1,
      pendingCheckbox: [],
      aprroveCheckbox: [],
      rejectCheckbox: [],
      pending: [],
      approved: [],
      rejected: [],
    };
  },
  watch: {},
  methods: {
    manageAllCheckboxPending(param) {
      if (param) {
        for (let i = 0; i < this.pending.length; i++) {
          this.pendingCheckbox.push(this.pending[i].id);
        }
      } else {
        this.pendingCheckbox = [];
      }
      this.manageSelectBox();
    },

    manageSelectBox() {
      if (this.pendingCheckbox.length > 0) {
        this.$emit("select-box", true);
        this.$emit("selected-rows", this.pendingCheckbox);
      } else {
        this.$emit("select-box", false);
        this.$emit("selected-rows", []);
      }
    },

    manageAllCheckboxApproved(param) {
      if (param) {
        for (let i = 0; i < this.approved.length; i++) {
          this.aprroveCheckbox.push(this.approved[i].id);
        }
      } else {
        this.aprroveCheckbox = [];
      }
      this.manageApproveSelectBox();
    },

    manageApproveSelectBox() {
      if (this.aprroveCheckbox.length > 0) {
        this.$emit("select-box", true);
        this.$emit("selected-rows", this.aprroveCheckbox);
      } else {
        this.$emit("select-box", false);
        this.$emit("selected-rows", []);
      }
    },
    manageAllCheckboxRejected(param) {
      if (param) {
        for (let i = 0; i < this.rejected.length; i++) {
          this.rejectCheckbox.push(this.rejected[i].id);
        }
      } else {
        this.rejectCheckbox = [];
      }
      this.manageRejectSelectBox();
    },

    manageRejectSelectBox() {
      if (this.rejectCheckbox.length > 0) {
        this.$emit("select-box", true);
        this.$emit("selected-rows", this.rejectCheckbox);
      } else {
        this.$emit("select-box", false);
        this.$emit("selected-rows", []);
      }
    },
    getLeave(status, curentPage) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "leave",
          data: { status: status, current_page: curentPage },
        })
        .then(({ data }) => {
          // console.log(data, "data");
          if (status == "pending") {
            if (data.current_page == 1) {
              _this.pending = data.rows;
            } else {
              _this.pending = _this.lodash.concat(_this.pending, data.rows);
            }
            _this.current_page = data.current_page + 1;
            _this.pendingTotalRows = data.total_rows;
          } else if (status == "approved") {
            if (data.current_page == 1) {
              _this.approved = data.rows;
            } else {
              _this.approved = _this.lodash.concat(_this.approved, data.rows);
            }

            _this.current_pageone = data.current_page + 1;
            _this.approvedTotalRows = data.total_rows;
          } else if (status == "rejected") {
            if (data.current_page == 1) {
              _this.rejected = data.rows;
            } else {
              _this.rejected = _this.lodash.concat(_this.rejected, data.rows);
            }
            _this.current_pagetwo = data.current_page + 1;
            _this.rejectTotalRows = data.total_rows;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    /*getCategoryOptions(userList) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "setting/leave/option/" + userList,
        })
        .then(({ data }) => {
          _this.categoryList = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },*/

    /* onRejectedDrop(collection, dropResult) {
      const { addedIndex, payload } = dropResult;
      if (this.lodash.isNull(addedIndex) == false) {
        this.update_loading = true;
        this.$store
          .dispatch(PATCH, { url: `leave/${payload.id}/rejected` })
          .then((output) => {
            console.log({ output });
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.update_loading = false;
          });
      }
      this[collection] = this.applyDrag(this[collection], dropResult);
    }, */

    reject_submit(params) {
      this.statusapprove = params;
      this.confirmationData;

      const { addedIndex, payload } = this.confirmationData.dropResult;
      if (this.lodash.isNull(addedIndex) == false) {
        this.update_loading = true;
        this.$store
          .dispatch(PATCH, {
            url: `leave/${payload.id}/rejected`,
            data: {
              leave_remark: this.leave_remark,
            },
          })
          .then((output) => {
            console.log({ output });
            this.leave_remark = null;
            this.getLeave("pending");
            this.getLeave("rejected");
            this.getLeave("approved");
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.update_loading = false;
          });
      }
      this[this.statusapprove] = this.applyDrag(
        this[this.statusapprove],
        this.confirmationData.dropResult
      );
      this.confirm_dialog = false;
    },

    approve_submit(params) {
      this.statusapprove = params;
      this.confirmationData;
      console.log(this.confirmationData, "1213");
      const { addedIndex, payload } = this.confirmationData.dropResult;

      if (this.lodash.isNull(addedIndex) == false) {
        this.update_loading = true;
        this.$store
          .dispatch(PATCH, {
            url: `leave/${payload.id}/approved`,
            data: {
              leave_remark: this.leave_remark,
            },
          })
          .then((output) => {
            console.log({ output });
            this.leave_remark = null;
            this.getLeave("pending");
            this.getLeave("rejected");
            this.getLeave("approved");
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.update_loading = false;
          });
      }
      this[this.statusapprove] = this.applyDrag(
        this[this.statusapprove],
        this.confirmationData.dropResult
      );
      this.confirm_dialog = false;
    },

    onApprovedDrop(collection, dropResult) {
      this.confirm_dialog = true;
      this.confirmationData = { collection, dropResult };
      // const { addedIndex, payload } = dropResult;

      // if (this.lodash.isNull(addedIndex) == false) {
      //   this.update_loading = true;
      //   this.$store
      //     .dispatch(PATCH, {
      //       url: `leave/${payload.id}/approved`,
      //       data: {
      //         leave_remark: this.leave_remark,
      //       },
      //     })
      //     .then((output) => {
      //       console.log({ output });
      //     })
      //     .catch((error) => {
      //       this.logError(error);
      //     })
      //     .finally(() => {
      //       this.update_loading = false;
      //     });
      // }
      // this[collection] = this.applyDrag(this[collection], dropResult);
    },
    onPendingDrop(collection, dropResult) {
      const { addedIndex, payload } = dropResult;
      if (this.lodash.isNull(addedIndex) == false) {
        this.update_loading = true;
        this.$store
          .dispatch(PATCH, { url: `leave/${payload.id}/pending` })
          .then((output) => {
            console.log({ output });
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.update_loading = false;
          });
      }
      this[collection] = this.applyDrag(this[collection], dropResult);
    },

    getChildPayload1(index) {
      return this.pending[index];
    },
    getChildPayload2(index) {
      return this.approved[index];
    },
    getChildPayload3(index) {
      return this.rejected[index];
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;
      const result = [...arr];
      let itemToAdd = payload;
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }
      return result;
    },

    updateLeave(id) {
      this.$emit("update:leave", id);
    },
  },
  mounted() {
    this.getLeave("pending");
    this.getLeave("rejected");
    this.getLeave("approved");
    LeaveApplyEventBus.$on("reload:leave", () => {
      //  console.log(params,"params");
      this.getLeave("pending");
      this.getLeave("rejected");
      this.getLeave("approved");
    });

    //this.getCategoryOptions();
  },
  components: {
    Container,
    Draggable,
    Dialog,
  },
};
</script>
